import { createApp } from 'vue';
import App from './App.vue';
import { store } from './stores';
import router from './router';
import '@vant/touch-emulator'
// 全局样式
import './styles/index.less';
import './styles/font.less';

const app = createApp(App);
app.use(store);
app.use(router);

app.mount('#app');
