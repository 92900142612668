import Layout from '@/layout/index.vue';
import type { RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';


const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'root',
    component: Layout,
    redirect: 'home',
    meta: {
      title: '水木AI：全球混合模型技术引领者',
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: {
          title: '水木AI：全球混合模型技术引领者',
        },
      }
    ],
  },
];

export default routes;
